<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <header>
      <h1>{{ $t("INVOICE") }}</h1>
      <hr />
    </header>

    <transition name="devis">
      <section v-if="currentStep == 1">
        <b-button
          class="civ"
          variant="danger"
          @click="currentStep++"
          v-if="
            isentrepriseAdmin || isSuperAdmin || isAssistant || isCommercial
          "
        >
          {{ $t("CREATE") }} {{ $t("INVOICE") }}
        </b-button>
        <router-link to="/facture/list">
          {{ $t("Liste des factures") }}
        </router-link>
        <router-link to="/acompte/list">
          {{ $t("Liste des acomptes") }}
        </router-link>
        <router-link to="/avoir/list">
          {{ $t("Liste des avoirs") }}
        </router-link>
      </section>
    </transition>

    <transition name="devis">
      <section v-if="currentStep == 2">
        <router-link to="/facture/list">
          {{ $t("GENERATE") }} {{ $t("FROM_INVOICE") }}
        </router-link>
        <router-link to="/devis/list">
          {{ $t("GENERATE") }} {{ $t("FROM_ESTIMATE") }}
        </router-link>
        <router-link class="civ" to="/facture/manuelle">
          {{ $t("MANUAL_INVOICE") }}
        </router-link>
      </section>
    </transition>

    <!-- <section class="banner-bottom" :class="{ reduce: getTabs }">
      <hr class="red" />
    </section> -->
    <CommentMarche to="/how-it-work/facture"></CommentMarche>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CommentMarche from "../components/commentMarche.vue";

export default {
  components: {
    CommentMarche,
  },
  data() {
    return {
      currentStep: 1,
      page: 1,
      perPage: 10,
    };
  },

  methods: {
    ...mapActions(["devis"]),
    pagination(paginate) {
      this.page = paginate;
      this.devis({
        page: this.page,
        per_page: this.perPage,
      });
    },
  },

  computed: {
    ...mapGetters([
      "getTabs",
      "getAlldevis",
      "getTotalRowdevis",
      "getOnlineUser",
    ]),
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    isAssistant() {
      return this.getOnlineUser.role == "user.assistant";
    },
    isCommercial() {
      return this.getOnlineUser.role == "user.commercial-entreprise";
    },
  },
  mounted() {
    this.devis({ page: this.page, per_page: this.perPage });
  },
};
</script>

<style lang="scss" scoped>
header {
  width: 80%;
  margin: 84px auto 24px;

  h1 {
    font-weight: bold;
    font-size: 35px;
    color: #0f0f0f;
    margin: 0;
  }

  span,
  a {
    text-decoration: none;
    cursor: pointer;
    font-size: 18px;
    color: #515151;
  }
}

section {
  @media only screen and (max-width: 700px) {
    padding: 15px;
    width: 100%;
  }
  min-height: 70vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 12%;

  button,
  a {
    @media only screen and (max-width: 700px) {
      max-width: 80%;
      font-size: 12px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    width: 650px;
    padding: 16px 0;
    max-width: 50%;
    margin-bottom: 32px;
    color: #fff;
    background-color: #28367a;
    border-color: #28367a;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 800;

    &:hover {
      text-decoration: none;
      color: #fff;
      background-color: #1f295d;
    }
  }

  .civ {
    background-color: #e4261b;
    border-color: #e4261b;

    &:hover {
      background-color: #c22017;
      border-color: #c22017;
    }
  }
}

hr {
  margin: 4px 0;
  border-color: rgba($color: #707070, $alpha: 0.3);
}

.banner-bottom {
  @media only screen and (max-width: 900px) {
    left: 0;
  }
  position: fixed;
  background-color: #28367a;
  min-height: 58px;
  left: 64px;
  bottom: 0;
  right: 0;

  .red {
    @media only screen and (max-width: 900px) {
      bottom: 0;
      bottom: 0;
    }
    position: absolute;
    width: 25%;
    height: 0px;
    border: none;
    border-top: 6px solid #e4261b;
    bottom: 0;
    left: 0;
    margin: 0;
  }
}

.reduce {
  left: 220px;
}
</style>
