<template>
  <div>
    <router-link class="circle" :to="to">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
        />
      </svg>
    </router-link>
    <b-modal
      id="modal-xl"
      size="xl"
      title="Tutoriel"
      hide-footer
      centered
      scrollable
    >
      <div class="container">
        <div class="colone-1">
          <h1>Comment ça marche ?</h1>
          <p>
            Ceci est une page qui explique comment fonctionne quelque chose.
          </p>
          <h2>Étape 1 : Faire ceci</h2>
          <p>Ceci est la première étape du processus.</p>
          <h2>Étape 2 : Faire cela</h2>
          <p>Ceci est la deuxième étape du processus.</p>
          <h2>Étape 3 : Faire autre chose</h2>
          <p>Ceci est la troisième étape du processus.</p>
        </div>
        <div class="colone-2">
          <!-- Mettre les images correspondant aux étapes -->
          <video
            src="https://beta-api.photos-go.com/public/photographers/videos/1678791024_10%20Second%20Video..mp4"
            controls
          ></video>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      default: "/how-it-work",
    },
  },
};
</script>

<style lang="scss" scoped>
.circle {
  cursor: pointer;
  position: fixed;
  bottom: 24px;
  right: 24px;
  background-color: #28367a;
  border: 1px solid #28367a;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: #fff;
  }

  &:hover {
    background-color: #1f295d;
    border-color: #1b2554;
  }
}

.container {
  @media only screen and (max-width: 1000px) {
    display: block;
  }
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
/* Définir le style des colonnes */
.colone-1 {
  @media only screen and (max-width: 1000px) {
    overflow: visible;
    max-height: none;
  }
  padding: 20px;
  max-height: 500px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #28367a;
    box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.1);
  }
}
.colone-2 {
  padding: 20px;
  display: flex;
  align-items: center;
}
/* Définir le style des titres */
h2 {
  font-size: 24px;
  margin-top: 0;
}
/* Définir le style des images */
img,
video {
  width: 100%;
  height: auto;
}
</style>
